import SubHeader from "@/components/SubHeader";
import { RoleDetailsContainer } from "@shared/components/roles-and-permissions";

export default {
    name: "roles-and-permissions",
    components: {
        SubHeader,
        RoleDetailsContainer,
    },
    computed: {
        roleId() {
            return this.$route.params.id;
        },
    },
};
